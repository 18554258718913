@media (max-width: 768px) {

    .login__right {
        display: none;
    }

    .modal-dialog {
        width: 100%!important;
        min-width: 0!important;
    }

    .modal-content {
        width: 96% !important;
    }

    .buttons .exercise-btn {
        margin-left: 0 !important;
        margin-top: 15px!important;
    }
}