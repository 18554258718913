@import url("https://fonts.googleapis.com/css?family=Plus+Jakarta+Sans:200,300,regular,500,600,700,800,200italic,300italic,italic,500italic,600italic,700italic,800italic");
@import url("https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic");
@import url("https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic");

* {
	padding: 0;
	margin: 0;
	border: 0;
	scroll-behavior: smooth;
}

*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

:focus,
:active {
	outline: none;
}

a:focus,
a:active {
	outline: none;
}

nav,
footer,
header,
aside {
	display: block;
}

html,
body {
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body.modal-open {
	padding: 0px !important;
	overflow: auto !important;
}

input,
button,
textarea {
	font-family: inherit;
}

input::-ms-clear {
	display: none;
}

button {
	cursor: pointer;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

a,
a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

a {
	text-decoration: none !important;
}

ul li {
	list-style: none;
}

img {
	vertical-align: top;
	width: 100%;
	object-fit: cover;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: 400;
}

select {
	-webkit-appearance: none;
	-moz-appearance: none;
}

p {
	margin-bottom: 0 !important;
}

select::-ms-expand {
	display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type="number"] {
	-moz-appearance: textfield;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	-webkit-appearance: none !important;
}

body {
	font-family: "Plus Jakarta Sans", sans-serif;
	color: #171717;
	font-size: 14px;
	line-height: 22px;
	transition: 0.3s;
}

a {
	color: #000 !important;
}

input,
.group-checkbox .dropdown-toggle {
	border-radius: 36px !important;
	padding: 14px, 20px, 14px, 20px !important;
	gap: 10px !important;
}

input:focus,
textarea:focus,
select:focus {
	outline: none;
	box-shadow: none !important;
}

.modal {
	font-family: "Plus Jakarta Sans", sans-serif;
}

.button {
	padding: 12px 60px;
	border-radius: 20px;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
}

.btn-grey {
	background: #707070;
	border: 1px solid #707070;
	color: #ffffff !important;
}

.btn-add {
	background: #1ae5be;
	border: 1px solid #11cabe;
	color: #101110 !important;
}

.container {
	max-width: 95%;
	width: 1200px;
	margin: 0 auto;
}

.login {}

.login__left {
	height: 100vh;
}

.login__right {
	height: 100vh;
	background-color: #1ae5be;
}

.login-left__title {}

.login__btn {
	background-color: #1ae5be !important;
	color: #171717 !important;
	border: 1px solid #1ae5be !important;
	padding: 14px, 49px, 14px, 49px !important;
	border-radius: 46px !important;
	font-size: 20px !important;
	line-height: 32px !important;
	font-weight: 500 !important;
}

.login-left__title {
	color: #707070 !important;
	line-height: 28px;
	font-size: 16px;
}

.input-password {
	position: relative;
}

.password-icon {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 12px;
	cursor: pointer;
}

#infoForm input,
#login_password {
	padding: 12px 35px 12px 12px !important;
}

.password-icon svg {
	width: 15px;
	height: 15px;
}

.password-forget {
	color: #2f54e9 !important;
	font-weight: 500;
	font-size: 16px;
	line-height: 28px;
}

.radio-btn {
	background-color: #1ae5be !important;
	border-radius: 4px !important;
	height: 16px !important;
	width: 16px !important;
	border: 1px solid #1ae5be !important;
}

.login__right {
	display: flex;
	align-items: center;
	justify-content: center;
}

.login__background {
	border: 2px solid #ffffff;
	font-family: "Plus Jakarta Sans", sans-serif;
	border-radius: 30px;
	background: linear-gradient(148.8deg,
			rgba(255, 255, 255, 0.672) 2.36%,
			rgba(255, 255, 255, 0.208) 96.99%);
	height: 85vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 80%;
}

.login__top {
	position: relative;
	height: 70%;
}

.login__img {}

.login__img-front {
	/* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; */
	min-width: 150px;
}

.img-container_login {
	display: flex;
	align-items: flex-start;
	position: absolute;
	top: 50%;
	left: 40%;
	transform: translate(-50%, -50%);
}

.login__img-back {
	/* position: absolute;
  top: 7%;
  left: 11%; */

	position: relative;
	top: 36px;
	left: 100px;
	z-index: -1;
	min-width: 100px;
}

.login__qr {
	position: absolute;
	top: 50%;
	left: 70%;
	transform: translateY(-50%);
}

.login-qr__img {
	max-width: 120px;
	max-height: 120px;
}

.login-info {
	max-width: 120px;
	font-size: 12px;
	font-weight: 600;
	text-align: center;
}

.login__bottom {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.login__bottom h1 {
	font-weight: 600;
}

.login__text {
	width: 50%;
}

.icon-btns {
	font-size: 5px;
}

@media (max-width: 1440px) {
	.login__img-back {
		left: 65px;
		top: 20px;
	}

	.login-qr__img {
		max-width: 90px;
		max-height: 90px;
	}

	.login-info {
		max-width: 90px;
		font-size: 12px;
		font-weight: 600;
		text-align: center;
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	background: linear-gradient(180deg, #1ae5be 0%, #09af8f 100%);
}

.header__logo {
	width: 221px;
	/* height: 31px; */
}

.header-actions {}

.header-message {}

.header-notification {}

.header-img {
	width: 48px;
	height: 48px;
}

.header-img img {
	border-radius: 100%;
}

.header-message,
.header-notification {
	position: relative;
}

.header-message-count,
.header-notification-count {
	position: absolute;
	top: 0;
	right: 0;
	background: #fc5a5a;
	border: 2px solid #11cabe;
	width: 10px;
	height: 10px;
	border-radius: 50%;
}

.header-message-count {}

.header-notification-count {}

.header-profile {
	cursor: pointer;
}

.profile-menu-dropdown .dropdown-menu {
	right: 0 !important;
	/* transform: translate3d(440%, 65px, 0px) !important; */
	/* transform: translate3d(440%, 65px, 0px) !important; */
	width: 280px;
	box-shadow: 0px 40px 64px -12px #00000014;
	padding: 16px;
	border-radius: 16px;
}

.profile-menu-dropdown .dropdown-item {
	width: 100%;
}

.profile-menu-dropdown .dropdown-menu>a {
	display: flex;
	align-items: center;
	padding: 10px 7px;
	border-radius: 10px;
}

.profile-menu-dropdown .dropdown-menu>a:last-of-type {
	margin-bottom: 0;
}

.profile-menu-nav {
	font-family: "Inter", sans-serif !important;
	color: #6f767e !important;
	font-size: 15px;
	font-weight: 600;
	line-height: 24px;
	margin-left: 10px;
}

.profile-menu-dropdown .dropdown-item:active {
	background-color: transparent !important;
}

.icons {
	display: flex;
	gap: 20px;
}

.icon {
	font-size: 24px;
	cursor: pointer;
}

.icon:hover {
	color: #007bff;
}

.ps-sidebar-container {
	overflow-y: unset !important;
	overflow-x: unset !important;
}

.ps-sidebar-container div {
	background: #fff !important;
}

.ps-menu-label a {
	display: inline-block;
	height: 100%;
	width: 100%;
}

.ps-menu-label a.active span {
	color: #1ae5be;
}

.admin {
	height: 100vh;
}

.sidebar-btn {
	position: absolute;
	top: 10px;
	right: -43px;
	z-index: 1000;
	background: #f5f5f7;
	box-shadow: 0px 0px 25px 0px #f5f5f7;
	width: 43px;
	height: 53px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 8px solid #fff;
}

.app-container {
	display: flex;
	min-height: 100vh;
}

.sidebar {
	flex-shrink: 0;
	transition: width 0.3s ease;
	font-family: "Poppins", sans-serif;
}

.ps-sidebar-container {}

.sidebar.collapsed {
	width: 80px;
}

.main-content {
	flex-grow: 1;
	overflow-x: auto;
	transition: margin-left 0.3s ease;
	padding: 50px;
	background: #9f9f9f33;
}

.app-container.collapsed .main-content {
	margin-left: 80px;
}

.app-container.expanded .main-content {
	margin-left: 250px;
}

.ps-menu-button {
	display: flex;
	align-items: center;
}

.ps-menu-button svg {
	margin-right: 20px;
}

.ps-menu-button span {
	color: #101110;
	line-height: 21px;
	letter-spacing: 0.1px;
	font-weight: 500;
}

.ps-menu-icon {
	width: max-content !important;
}

.ps-submenu-root svg {
	margin-right: 10px;
}

.user__wrapper {}

.user__left {}

.user__top {
	background: #fff;
	border-radius: 20px;
	padding: 20px;
	display: flex;
	align-items: center;
}

.user__img {
	width: 110px;
	height: 110px;
	margin-right: 25px;
}

.user__img img {
	border-radius: 100%;
}

.user__info {
	font-family: "Plus Jakarta Sans", sans-serif;
}

.user__name {
	font-size: 24px;
	line-height: 36px;
	font-weight: 600;
}

.user__group {
	font-weight: 600;
	margin-top: 11px;
}

.user__email,
.user__access {
	font-size: 12px;
	line-height: 15px;
	font-weight: 500;
}

.user__email {
	color: #8f8f8f;
	margin-bottom: 15px !important;
}

.user__access {
	margin-bottom: 15px !important;
}

.user__lesson {
	background: #f1f1f1;
	border-radius: 10px;
	padding: 10px;
	gap: 10px;
}

.user__lesson svg {
	margin-right: 10px;
}

.user__lesson span {
	color: #000000;
	font-size: 10px;
	font-weight: 600;
	line-height: 15px;
	text-transform: uppercase;
}

.user__card-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.user__title {
	color: #000000;
	font-size: 20px;
	font-weight: 700;
	line-height: 25px;
	font-family: "Plus Jakarta Sans", sans-serif;
}

.user__groups-item {
	background: #ffffff;
	padding: 10px 40px 10px 20px;
	border-radius: 20px;
}

.user__groups-date {
	color: #828282;
	font-weight: 600;
	line-height: 21px;
	margin-bottom: 15px !important;
}

.user__groups-name {
	color: #000000;
	font-size: 20px;
	line-height: 30px;
	font-weight: 600;
}

.user__groups-code {
	color: #27104e;
	font-size: 16px;
	line-height: 24px;
}

.user__group-name {
	max-width: 150px;
}

.grade-week .group-subject {
	margin-right: 10px;
}

.user__bottom {
	background: #1011101a;
	border-radius: 20px;
	padding: 30px;
}

.menu-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.menu-label {
	color: #101110;
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	margin-top: 10px;
	text-align: center;
}

.menu-icon {
	/* background: linear-gradient(180deg, #2d81ee 0%, #44c2fd 100%); */
	width: 100px;
	height: 100px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.user__right {}

.top {
	background: #fff;
	border-radius: 20px;
	padding: 30px;
}

.top__head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;
}

.top__label {
	color: #2c2c2c;
	font-weight: 700;
	font-size: 20px;
	margin-bottom: 0;
}

.top__filter {
	border-radius: 12px;
	/* width: 100%; */
}

.top__filter .selection {
	width: 100% !important;
}

.top__filter-label {
	padding: 0 10px;
	color: #92929d;
}

.top__filter-arrows {
	display: flex;
	flex-direction: column;
	padding: 5px;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
	border-left: 1px solid #e2e2ea;
}

.top__filter-arrows button {
	background-color: transparent;
	line-height: 14px;
}

.top__list {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.top__item {
	width: 100%;
	margin-bottom: 15px;
}

.top__icon {}

.top__img {
	margin: 0 15px;
}

.top__member {}

.top__name {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	letter-spacing: -2%;
	color: #2c2c2c;
}

.top__group {
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 1%;
	color: #27104e80;
}

.top__score {
	font-size: 20px;
	font-weight: 500;
	line-height: 25px;
	color: #585858;
}

/* TABLES CSS */
.groups {
	background-color: #fff;
	border-radius: 8px;
	position: relative;
}

.groups .groups-header {
	padding: 10px 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.add-button {
	background-color: #1ae5be;
	color: #000;
	display: flex;
	align-items: center;
	justify-self: center;
	gap: 10px;
	padding: 7px 24px;
	border-radius: 10px;
	font-weight: 700;
}

.groups .groups-header .add-button span {
	font-size: 13px;
	font-weight: 700;
}

.groups.no-add-layer .groups-header {
	justify-content: end;
}

.groups .groups-header .header-right {
	display: flex;
	align-items: center;
	gap: 15px;
}

.groups .groups-header .header-right .select-filter {
	appearance: none;
	border: 1px solid #e2e2ea;
	padding: 7px 30px 7px 12px !important;
	border-radius: 10px;
	font-size: 14px;
	background-image: url("/public/img/arrow-down.svg") !important;
	background-position: calc(100% - 10px) center !important;
	background-size: 8px;
	background-repeat: no-repeat !important;
}

.groups .groups-header .header-right .select-filter.active {
	background-image: url("/public/img/arrow-up.svg") !important;
}

.groups .groups-header .header-right .search-box {
	position: relative;
}

.groups .groups-header .header-right .search-box input {
	padding: 7px 32px 7px 12px !important;
	background-image: url("/public/img/search.svg") !important;
	background-position: calc(100% - 10px) center !important;
	background-size: 19px !important;
	background-repeat: no-repeat !important;
	border: 1px solid #707070;
	border-radius: 10px;
	font-size: 14px;
}

/* TABLE */
.groups .groups-body table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 10px;
}

table .column-content {
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: start;
	font-weight: 500;
	font-size: 14px;
	text-align: start;
	padding: 7px 20px 7px 0;
}

.grade-table .column-content {
	padding: 7px 5px 7px 0;
}

table .bg-white {
	background-color: #fff !important;
}

table .column-content .grade-input {
	padding: 5px 8px !important;
	height: 31px;
	color: #101110;
	font-weight: 600;
	line-height: 21px;
	width: 60px;
	text-align: center;
}

table .grade-input.q {
	background: #fc5a5a !important;
	color: #dfe1f3 !important;
}

table .grade-input.plus {
	background: #246CF9 !important;
	color: #dfe1f3 !important;
}

table .column-content .evaluate-input {
	border-radius: 8px !important;
}

table .column-content.grade .grade-btn,
.grade-input.evaluate-input {
	background: #b3b3b3 !important;
}

table .column-content.average input {
	background: #1ae5be !important;
}

.table-icon {
	cursor: pointer;
}

table thead .left {
	padding-left: 20px;
}

table tbody .left {
	padding-left: 18px;
	margin-left: 11px;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

table tbody .right {
	padding-right: 18px;
	margin-right: 11px;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

/* table header */
table thead {
	background-color: #2c2c2c;
	color: #fff;
}

table thead .column-content {
	font-weight: 600;
}

/* table body */
table tbody tr:nth-child(odd) td .column-content {
	background-color: #e5e6ed80;
}

table tr .operation-buttons {
	display: flex;
	align-items: center;
	gap: 10px;
}

.operation-btn {
	height: 31px;
	width: 31px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
}

.blue-btn {
	background-color: #246cf9;
}

.green-btn {
	background-color: #3ac0a6;
}

.gray-btn {
	background-color: #585858;
}

.red-btn {
	background-color: #fc5a5a;
}

.purple-btn {
	background-color: #ae24f9;
}

.light-gray-btn {
	background-color: #cccccc;
}

.groups .groups-footer {
	display: flex;
	align-items: center;
	justify-content: end;
	padding: 20px 17px 18px;
	gap: 15px;
}

.groups .groups-footer .pagination * {
	font-size: 14px;
	color: #92929d;
}

.groups .groups-footer .pagination ul li:not(li:first-child, li:last-child) button {
	border: none !important;
	background-color: transparent;
}

.groups .groups-footer .pagination ul button.Mui-selected {
	color: #1ae5be;
	font-weight: 700;
}

.groups .groups-footer .pagination ul li button.Mui-selected:hover {
	background-color: transparent;
}

.groups .groups-footer .input-number input {
	appearance: none;
	font-size: 14px;
	padding: 7px 12px !important;
	border: none !important;
	border-right: 1px solid #e2e2ea !important;
	border-radius: 0px !important;
	color: #92929d;
	background: #fff !important;
}

.groups .groups-footer .input-number {
	position: relative;
	border: 1px solid #e2e2ea;
	border-radius: 10px;
	overflow: hidden;
	display: flex;
	align-items: center;
}

.groups .groups-footer .input-number .number-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 6px;
	padding: 0 7px;
}

.groups .groups-footer .input-number .number-button button {
	cursor: pointer;
	display: grid;
	place-items: center;
	background-color: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

.groups .groups-body .avatar-image {
	object-fit: cover;
	width: 32px;
	height: 32px;
	border-radius: 50%;
}

.footer {
	background: #707070;
	background-color: rgba(112, 112, 112, 0.2);
	color: #585858;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 2%;
	font-weight: 500;
}

.footer__wrapper {}

.footer__copyright {}

.footer__language {
	margin-bottom: 0 !important;
}

.list-inline-item.active {
	color: rgba(88, 88, 88, 0.5);
}

.modal-content {
	border-radius: 20px !important;
}

label {
	color: #33383f;
	font-weight: 600;
	line-height: 24px;
}

input:not(input[type="radio"], input[type="checkbox"]),
select,
.group-checkbox .dropdown-toggle {
	border: 2px solid #efefef !important;
	padding: 12px !important;
	border-radius: 12px !important;
	background: #fcfcfc !important;
}

.form-check-input:checked {
	background-color: #1ae5be !important;
	border-color: #1ae5be !important;
}

.group-checkbox .dropdown-toggle {
	position: relative;
	color: #212529;
	width: 100%;
	text-align: left;
	overflow-wrap: break-word;
	white-space: normal;
}

.group-checkbox .dropdown-toggle svg {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
}

.group-checkbox .dropdown-toggle:hover,
.group-checkbox .dropdown-toggle:focus,
.group-checkbox .dropdown-toggle:active {
	color: #212529 !important;
}

.btn.show {
	color: #212529 !important;
}

.add-dropdown-menu {
	width: 100%;
	max-height: 150px !important;
	overflow-y: scroll !important;
}

.dropdown-toggle::after {
	display: none !important;
}

.modal-dialog {
	min-width: 700px;
}

.select-form {
	position: relative;
}

.select-form .position-relative select {
	padding-right: 35px;
}

.select-form .position-relative svg {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
}

.invalid-feedback {
	display: block !important;
}

.input-error,
#form-input .input-error,
.is-invalid.dropdown-toggle,
.input-error.form-control.is-invalid {
	border: 1px solid #dc3545 !important;
}

.custom-checkbox .time-input.input-error {
	border: 1px solid #dc3545 !important;
	box-shadow: none;
}

.error-message {
	color: #dc3545;
	font-size: 0.875rem;
}

.error {
	color: #dc3545;
	font-size: 16px;
}

.modal-btn {
	width: 100%;
}

.btn-green {
	background: #1ae5be !important;
	border: 1px solid #1ae5be !important;
	padding: 14px, 49px, 14px, 49px !important;
	border-radius: 10px !important;
	color: #171717 !important;
	font-size: 20px !important;
	font-weight: 500 !important;
	line-height: 32px !important;
	align-items: center !important;
}

.modal-title {
	border: 1px solid #e7e7e7;
	padding: 5px 10px;
	border-radius: 7px;
}

.modal-title span {
	color: #92929d;
	font-weight: 500;
	line-height: 17px;
	margin-left: 8px;
}

.modal-header {
	border-bottom: none !important;
}

.modal-footer {
	border-top: none !important;
}

.custom-radio {
	position: relative;
	margin-right: 50px;
}

.custom-radio .form-check-label {
	position: absolute;
	top: 50%;
	left: 0.5rem;
	transform: translateY(-50%);
	padding: 0;
	margin: 0;
	/* width: 2rem; */
	/* height: 2rem; */
	border-radius: 20px;
	background-color: white;
	text-align: center;
	/* line-height: 2rem; */
	border: 1px solid #ccc;
	padding: 5px 15px;
}

.custom-radio .form-check-input {
	position: absolute;
	opacity: 0;
}

.custom-radio .form-check-input:checked~.form-check-label {
	background-color: #007bff;
	color: white;
	border-color: #007bff;
}

.custom-radio .form-check-input:focus~.form-check-label {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-checkbox-wrapper {
	display: flex;
	margin-bottom: 0.5rem;
	flex-direction: column;
}

.custom-checkbox {
	position: relative;
	display: inline-block;
	margin-right: 60px;
}

.custom-checkbox-row div:last-of-type {
	margin-right: 0 !important;
}

.custom-checkbox .checkbox-label {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	border: 1px solid #ced4da;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	padding: 15px 35px;
	margin-right: 20px;
	text-align: center;
}

.custom-checkbox .no-border-label {
	border: none !important;
}

.custom-checkbox input:checked+.checkbox-label {
	background-color: #1ae5be;
	border-color: #1ae5be;
}

.custom-checkbox input:checked+.checkbox-label::after {
	color: #fff;
	font-size: 1rem;
}

.custom-checkbox-wrapper {
	display: flex;
	flex-direction: column;
}

.custom-checkbox {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}

.custom-checkbox input[type="checkbox"] {
	position: relative;
	margin-right: 10px;
	appearance: none;
	width: 1.5rem;
	height: 1.5rem;
	/* border: 1px solid #ced4da; */
	border-radius: 20px;
	background-color: #fff;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.custom-checkbox input[type="checkbox"]:checked {
	/* background-color: #1ae5be !important;
  border-color: #1ae5be !important; */
}

.custom-checkbox .checkbox-label {
	display: flex;
	align-items: center;
}

.custom-checkbox .time-input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid #ced4da;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	padding: 15px 35px;
	margin-right: 20px;
}

.custom-checkbox .time-input {
	background-color: #fff;
	color: #000;
	border: 1px solid #ced4da;
	border-radius: 20px;
	padding: 12px 11px !important;
	text-align: center;
	width: 100%;
	height: 100%;
}

.custom-checkbox.active .time-input {
	background-color: #1ae5be !important;
	border: 2px solid #1ae5be !important;
}

.groups .groups-body .custom-dropdown {
	background-color: transparent;
	position: relative;
}

.groups .groups-body .custom-dropdown .dropdown-header {
	background-color: #246cf9;
	color: #fff !important;
	padding: 5px 12px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
	font-size: 14px;
	cursor: pointer;
	max-width: 130px;
	border-color: transparent !important;
}

.groups .groups-body .custom-dropdown .dropdown-body {
	box-shadow: 0px 32px 48px -8px #0000001a !important;
	box-shadow: 0px 0px 14px -4px #0000000d !important;
	box-shadow: 0px 40px 64px -12px #00000014 !important;
	border: none !important;
}

.groups .groups-body .custom-dropdown .dropdown-body.active-dropdown {
	display: block;
}

.groups .groups-body .custom-dropdown .dropdown-body .dropdown-content {
	min-width: 280px;
	background-color: #ffffff;
	color: #6f767e !important;
	font-weight: 600;
	padding: 16px;
	border-radius: 16px;
}

.groups .groups-body .custom-dropdown .dropdown-body .dropdown-content div {
	padding: 12px 15px;
}

.no-group-message {
	color: #fc5a5a;
	font-weight: 500;
	font-size: 14px;
}

.alert-modal .alert-image {
	text-align: center;
	margin-bottom: 12px;
}

.alert-modal .alert-message {
	text-align: center;
}

.alert-modal .alert-message .alert-title {
	font-size: 36px;
}

.student-modal {
	display: flex;
	align-items: center;
	justify-content: center;
}

.student-modal .modal-dialog,
.graduate-modal .modal-dialog {
	max-width: 956px;
	top: 20% !important;
}

.student-modal .alert-modal .alert-message .alert-title {
	margin-bottom: 17px;
}

.student-modal .alert-modal .alert-message .alert-content {
	font-size: 20px;
	max-width: 370px;
	margin: 0 auto 17px;
}

.student-modal .alert-modal .alert-message .buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 22px;
	max-width: 422px;
	margin: 0 auto;
}

.student-modal .alert-modal .alert-message .buttons button {
	padding: 7px 20px;
	font-size: 22px;
	border-radius: 12px;
	width: 50%;
	border: none;
}

.student-modal .alert-modal .alert-message .buttons .no-button {
	background-color: #fc5a5a;
	color: #fff;
	font-weight: 500;
}

.student-modal .alert-modal .alert-message .buttons .yes-button {
	background-color: #1ae5be;
	color: #000;
	font-weight: 500;
}

.graduate-modal .modal-dialog .modal-content {
	background-color: transparent;
	border: none;
}

.graduate-modal .modal-dialog .modal-content .modal-title {
	color: #fff;
	border: none;
	font-size: 36px;
	text-align: center;
}

.graduate-modal .modal-dialog .modal-content .modal-title h2 {
	font-weight: 400;
	font-size: 36px;
}

.graduate-modal .g-modal-body {
	background-color: #fff;
	border-radius: 20px;
}

.graduate-modal .modal-body {
	max-width: 720px;
	width: 100%;
	margin: auto;
}

.graduate-modal .modal-body form {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.graduate-modal .modal-body form input {
	width: 70%;
}

.graduate-modal .modal-body form button {
	width: calc(30% - 10px);
	margin-left: 10px;
	padding: 7px 0;
	font-size: 20px;
	line-height: 32px;
	border-radius: 12px;
	font-weight: 500;
	background-color: #1ae5be;
	color: #000;
	border: none;
}

.graduate-modal .search-results .s-result {
	background-color: #e5e6ed80;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
	margin-top: 22px;
	padding: 7px 14px;
	font-size: 14px;
	font-weight: 500;
}

.graduate-modal .search-results .s-result .buttons {
	display: flex;
	align-items: center;
	gap: 11px;
}

.graduate-modal .search-results .s-result .buttons .choice-btn {
	background-color: #1ae5be;
	color: #000;
	border: none;
	border-radius: 10px;
	font-weight: 500;
}

.error-form {
	color: rgb(121, 10, 10);
}

.request {}

.request__wrapper {
	background: #fcfcfc;
	border-radius: 8px;
	padding: 12px;
	font-family: "Inter", sans-serif !important;
	height: 90vh;
}

.request__left {
	height: 100%;
}

.request__item {
	position: relative;
	transition: 0.3s;
	padding: 12px;
}

.request__item:hover {
	cursor: pointer;
	background: #efefef;
	border-radius: 12px;
}

.request-item__img {
	width: 56px;
	height: 56px;
	margin-right: 20px;
}

.request-item__img img {
	width: 60px;
	height: 60px;
	border-radius: 100%;
}

.request-item__info {}

.request__search .request-input {
	padding: 12px 12px 12px 40px !important;
}

.request-item__name {
	color: #1a1d1f;
	font-size: 15px;
	line-height: 24px;
	font-weight: 700;
}

.request-item__date {
	color: #9a9fa5;
	font-size: 13px;
	line-height: 16px;
	font-weight: 600;
	margin-right: 10px;
}

.request-item__message {
	color: #6f767e;
	font-size: 15px;
	line-height: 24px;
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 260px;
}

.request-item__icon {
	position: absolute;
	top: 13%;
	right: 1%;
	height: 12px;
	width: 12px;
	border-radius: 50%;
	margin-left: 20px;
}

.unread {
	background: #1ae5be;
}

.read {
	background: #9a9fa5;
}

.request__search {
	position: relative;
}

.request__search input {
	background: #f4f4f4 !important;
	padding: 8px !important;
	border-radius: 12px !important;
	border: 1px solid #f4f4f4 !important;
	color: #9a9fa5 !important;
	font-weight: 600 !important;
	font-size: 15px !important;
	width: 100%;
	padding-left: 40px !important;
}

.request__search input::placeholder {
	color: #9a9fa5 !important;
}

.request__search-icon {
	position: absolute;
	top: 22%;
	left: 3%;
}

.request__right {
	border: 1px solid #efefef;
	border-radius: 12px;
}

.request__top {
	display: flex;
	justify-content: space-between;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	background: #f4f4f4;
	padding: 24px;
}

.request-message-col {
	height: 80px;
}

.request__top-left {
	display: flex;
	align-items: center;
	gap: 20px;
}

.request-top__name {
	color: #1a1d1f;
	font-size: 20px;
	font-weight: 600;
	line-height: 32px;
}

.request-top__info {
	display: flex;
	color: #6f767e;
	font-size: 13px;
	font-weight: 500;
	line-height: 16px;
}

.request-top__role {
	position: relative;
	margin-right: 40px;
}

.request-top__role::after {
	content: "";
	position: absolute;
	top: 12%;
	right: -50%;
	background: #6f767e;
	opacity: 51%;
	height: 12px;
	width: 2px;
	border-radius: 2px;
}

.request-top__group {}

.request-top__delete {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fcfcfc;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	cursor: pointer;
}

.request__bottom {
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	padding: 24px;
}

.request__message {}

.request__message-img {
	width: 40px;
	height: 40px;
	margin-right: 20px;
}

.request-message__body {}

.request-message__info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
}

.request-message__name {
	margin-right: 20px;
	color: #6f767e;
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
}

.request-message__date {
	color: #9a9fa5;
	font-size: 13px;
	font-weight: 600;
	line-height: 16px;
}

.request-message__message {
	color: #1a1d1f;
	font-size: 15px;
	font-weight: 500;
	line-height: 24px;
}

.request__list {
	overflow-y: auto;
	scrollbar-width: none;
	height: calc(90vh - 184px);
	/* max-height: 600px; */
	/* align-items: flex-start; */
}

.request__list .request__row {
	height: 100%;
}

/* PROFILE PAGE */
.profile-title {
	font-family: "Plus Jakarta Sans", sans-serif;
	font-size: 36px;
}

.menu-request {
	position: relative;
}

.request-count {
	position: absolute;
	top: 0;
	right: 0;
	background: #fc5a5a;
	width: 28px;
	height: 22px;
	border-radius: 28px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.request-count span {
	color: #ffffff !important;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	line-height: 16.41px;
}

.profile-container {
	background-color: #fff;
	border-radius: 8px;
	padding: 24px;
	margin-top: 24px;
}

.pi-title {
	display: flex;
	align-items: center;
	gap: 16px;
	margin-bottom: 32px;
}

.profile-container .pi-title .pi-color {
	width: 16px;
	height: 32px;
	background-color: #b5e4ca;
	border-radius: 4px;
}

.profile-container .pi-content .pi-content-img {
	display: flex;
	align-items: center;
	gap: 32px;
}

.profile-container .pi-content .pi-c-image img {
	width: 110px;
	height: 110px;
	object-fit: cover;
	border-radius: 50%;
}

.profile-container .buttons {
	display: flex;
	gap: 12px;
}

.profile-container .pi-content .file-input,
.file-inputs,
.file-inputs-black {
	border-radius: 12px;
	background-color: #1ae5be;
	color: #000;
	border: none;
}

.file-inputs,
.file-inputs-black {
	width: 80%;
}

.about-form {
	width: 100%;
	padding: 15px;
}

.file-inputs-black {
	background-color: transparent;
	border: 2px solid #efefef;
}

.about-logo-img,
.about-logo-img-black {
	width: 10%;
	margin: 15px 15px 15px 0;
	background-color: #1ae5be;
	display: flex;
	align-items: center;
	justify-content: center;
}

.about-logo-img img {
	width: 80%;
}

.about-logo-img-black {
	margin-left: 10%;
	background-color: transparent;
}

.profile-container .pi-content .file-input label,
.file-inputs label,
.file-inputs-black label {
	padding: 12px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-weight: 700;
	font-size: 15px;
}

.profile-container .pi-content .file-input input,
.file-inputs input,
.file-inputs-black input {
	display: none;
}

.outlined-btn {
	background-color: transparent;
	color: #000;
	border-radius: 12px;
	font-weight: 700;
	font-size: 15px;
	padding: 12px 20px;
	border: 2px solid #efefef;
}

.outlined-btn:hover {
	background-color: transparent;
	color: #000;
	border: 2px solid #efefef;
}

.profile-container .profile-info .pi-form form .pi-form-wrapper {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 50px;
}

.profile-container .profile-info .pi-form .pi-form-inner {
	margin-top: 32px;
}

.profile-container .pi-form .pi-form-inner label {
	display: block;
	font-weight: 700;
	margin-bottom: 14px;
	font-size: 14px;
}

.profile-container .pi-form .pi-form-inner input {
	width: 100%;
}

.secondary-input {
	background-color: #f4f4f4 !important;
	border: none !important;
}

.secondary-input-disabled {
	border: none !important;
	background-color: #cccccc !important;
}

.profile-container .password-info .pi-title .pi-color {
	background-color: #cabdff;
}

.profile-container .password-info .pi-form .pi-form-wrapper {
	width: calc(50% - 25px);
}

.profile-container .password-info .pi-form .pi-form-inner {
	margin-top: 32px;
}

.profile-container .password-info .pi-form form .form-row {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 20px;
}

.divider {
	height: 1px;
	width: 100%;
	background-color: #efefef;
	border-radius: 1px;
	margin: 48px 0;
}

.save-btn-container {
	margin-top: 32px;
	text-align: end;
}

.save-btn-container .save-btn {
	padding: 12px 80px;
	border-radius: 12px;
	font-weight: 700;
	background-color: #1ae5be;
	color: #000;
	border: none;
	font-size: 15px;
}

.password-btn-container {
	width: calc(50% - 25px);
	text-align: end;
	margin-top: 32px;
}

.validation-error {
	color: red;
}

.form-check .form-check-input {
	float: none !important;
}

.group-checkbox .form-check,
.login .form-check {
	display: flex;
	align-items: center;
}

.group-checkbox input {
	appearance: unset !important;
}

.group-checkbox label {
	cursor: pointer;
	margin-left: 10px;
	width: 100%;
}

.group-checkbox .dropdown-menu {
	padding: 0 5px;
	padding: 10px;
}

.group-checkbox .form-check-input,
.login .form-check-input {
	appearance: none;
	-webkit-appearance: none;
	border-radius: 3px;
	width: 1.2em;
	height: 1.2em;
	position: relative;
	cursor: pointer;
}

/* .group-checkbox .form-check-input:checked::before,
.login .form-check-input:checked::before {
  font-family: "icomoon" !important;
  content: "\e901";
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
  font-size: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  width: 1.2em;
  height: 1.2em;
} */

.login .form-check-input {
	margin-right: 10px;
}

.group-checkbox .form-check-input:hover {}

.selection {
	width: auto !important;
	padding: 7px 30px 7px 12px !important;
	border-radius: 10px !important;
	border: 1px solid #e2e2ea !important;
	font-size: 14px;
	background-image: url("/public/img/arrow-down.svg") !important;
	background-position: calc(100% - 10px) center !important;
	background-size: 8px;
	background-repeat: no-repeat !important;
	color: #92929d !important;
}

.selection.active {
	background-image: url("/public/img/arrow-up.svg") !important;
}

.exercises {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.exercises .buttons button {
	color: #fff;
	padding: 15px 30px;
	font-weight: 700;
	border-radius: 20px;
	margin-left: 14px;
}

.exercises-navbar {
	display: flex;
	align-items: center;
	gap: 28px;
	font-size: 16px;
	padding: 0;
}

.exercises-navbar li a {
	color: #858585 !important;
	font-weight: 500;
}

.exercises-navbar li a.active {
	color: #000 !important;
}

.primary-button {
	background-color: #1ae5be;
	color: #101110 !important;
}

.exercise-cards {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
}

.exercise-cards .exercise-card {
	background-color: white;
	border-radius: 20px;
	padding: 10px 16px;
	cursor: pointer;
}

.exercise-card .ec-header {
	color: #828282;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
}

.exercise-card .ec-header button {
	background-color: transparent;
}

.exercise-card .ec-body {
	height: 38%;
}

.exercise-card .ec-body h4 {
	font-weight: 600;
	font-size: 20px;
}

.exercise-card .ec-body p {
	font-size: 16px;
}

.exercise-card .divider-card {
	margin: 16px 0;
	height: 1.5px;
	width: 100%;
	background-color: #d9d9d980;
}

.exercise-card .ec-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.exercise-card .ec-footer .ec-f_img img {
	width: 25px;
	height: 25px;
	object-fit: cover;
	border-radius: 50%;
	border: 1px solid #fff;
}

.exercise-card .ec-footer .ec-f_img img:not(img:first-child) {
	margin-left: -10px;
}

.exercise-card .ec-footer .group-name {
	display: block;
	color: #7580e8;
	font-size: 12px;
	font-weight: 700;
	background-color: #dce4ff;
	border-radius: 30px;
	padding: 5px 12px;
}

.exercise-card #dropdown-basic {
	border: none;
	padding: 0;
	max-width: 280px;
}

.exercise-card .di-wrapper {
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 12px;
	color: #6f767e;
	background-color: transparent;
	border-radius: 12px;
	transition: all 0.1s linear;
}

.exercise-card .di-wrapper:hover {
	background-color: #f4f4f4;
	color: #000;
}

.exercise-card .dropdown-item-text {
	color: #6f767e;
	font-size: 15px;
	padding: 0px;
	text-align: end;
	font-weight: 600;
}

.exercise-card .di-wrapper:hover .dropdown-item-text {
	color: #000;
}

.create-exercise * {
	font-family: "Plus Jakarta Sans", sans-serif;
}

.modal-header-divider {
	border-bottom: 1px solid #e2e2ea !important;
	align-items: baseline !important;
}

.create-exercise .modal-body {
	padding: 0 !important;
}

.create-exercise .mb-header-wrapper {
	padding: 30px 0px;
	margin: 0px 40px;
	border-bottom: 1px solid #e2e2ea !important;
}

.create-exercise .mb-header-wrapper .mb-header h2 {
	color: #171725;
	font-size: 24px;
	font-weight: 600;
}

.create-exercise .mb-header-wrapper .mb-header span {
	display: block;
	color: #696974;
}

.create-exercise .mb-header-wrapper .mb-header-details {
	margin-top: 30px;
	display: flex;
	align-items: center;
	/* gap: 137px; */
}

.create-exercise .mb-header-wrapper .mb-header-details .hd_title {
	margin-bottom: 12px;
	color: #92929d;
	font-weight: 600;
	font-size: 12px;
}

.create-exercise .mb-header-wrapper .mb-header-details .hd_left .hd-content.student-profile {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 12px;
}

.create-exercise .mb-header-wrapper .mb-header-details .hd_right .hd-content {
	display: flex;
	align-items: center;
	gap: 9px;
}

.create-exercise .mb-header-wrapper .mb-header-details .hd_left .hd-content.student-profile img {
	width: 32px;
	height: 32px;
	object-fit: cover;
	border-radius: 50%;
}

.create-exercise .mb-header-wrapper .mb-header-details .hd_left .hd-content.student-profile .group-profile {
	background-color: #ffc542;
	color: #ffffff;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
}

.create-exercise .mb-header-wrapper .mb-header-details .hd_right .hd-content {
	font-weight: 700;
	font-size: 15px;
}

.create-exercise .mf-wrapper {
	margin: 0px 40px;
	padding: 30px 0;
}

.create-exercise .mf-title {
	display: flex;
	align-items: start;
	gap: 20px;
}

.create-exercise h6 {
	font-size: 14px;
	font-weight: 600;
	color: #171725;
}

.create-exercise .mf-wrapper .content-wrapper {
	margin-left: 38px;
	margin-bottom: 50px;
}

.create-exercise .mf-wrapper .mf-top p {
	font-size: 14px;
	color: #44444f;
	font-family: "Roboto", sans-serif;
}

.create-exercise .modal-footer {
	padding: 0px !important;
}

.create-exercise .bold-title {
	font-weight: 700;
}

.turn-wrapper {}

.turn-wrapper span {
	display: block;
	padding: 5px 20px;
	font-weight: 600;
	font-family: "Plus Jakarta Sans", sans-serif;
	/* border-radius: 10px; */
}

.turn-wrapper tbody tr:nth-child(odd) span {
	background-color: #e5e6ed80;
}

.turn-left {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.turn-right {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.settings .settings-row {
	display: flex;
	align-items: start;
	justify-content: start;
	gap: 20px;
	width: 100%;
}

.settings-row .table-col {
	width: 50%;
}

.settings .settings-input {
	padding: 3px 10px !important;
	border-radius: 8px !important;
	width: 80px;
}

.settings .percent {
	display: block;
	width: 80px;
}

/* GRADE TEACHER FORM */
.grade {
	font-family: "Plus Jakarta Sans", sans-serif;
}

.group-subject-name {
	margin-right: 10px;
}

.group-subject-selection .selection {
	width: 80% !important;
}

.groups-header-grade {
	background: #ececec !important;
}

.calendar-popup {
	position: absolute;
	top: 50px;
	right: 100px;
	background: white;
	border: 1px solid #ddd;
	padding: 10px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
	z-index: 1000;
}

.week-current {
	color: #141518;
	font-size: 15px;
	font-weight: 700;
	line-height: 27px;
	background: #ececec;
	margin: 0 10px;
}

.groups-average p {
	color: #2c2c2c;
	font-weight: 500;
	line-height: 21px;
}

.group-weekly-average {
	margin: 0 40px;
}

.grade-btns {
	display: flex;
	justify-content: end;
	margin-top: 20px;
}

.grade-btns button:first-of-type,
.grade-btns a:first-of-type {
	margin-right: 20px;
}

.column-note {
	background: #ffdead !important;
	padding: 5px 10px !important;
	border-radius: 10px !important;
}

.plus-btn,
.q-btn {
	background: #707070;
	padding: 5px 8px;
	height: 31px;
	width: 80px !important;
	color: #dfe1f3;
	line-height: 21px;
	border-radius: 8px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
}

.plus-btn.checked {
	background: #09af8f !important;
	color: #282928;
}

.q-btn.checked {
	background: #fc5a5a !important;
}

.edit-note {
	background: #2c2c2c;
	padding: 5px 10px;
	border-radius: 8px;
	height: 31px;
	width: 50px;
}

.day-navigation {
	background: #ffffff;
	border-radius: 10px;
	color: #44444f;
	line-height: 24px;
	padding: 10px;
	padding: 7px 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.day-navigation svg {
	margin-left: 10px;
}

/* NOTE MODAL  */
.note-modal-label {
	color: #101110;
	font-size: 20px;
	font-weight: 700;
	line-height: 25px;
}

.note-modal .modal-dialog {
	min-width: 600px;
}

.note-modal .modal-content {
	padding: 10px 20px 20px;
}

.input-container {
	position: relative;
	width: 80px;
}

.percent-error {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 105%;
	background-color: #101110;
	color: #fff;
	font-weight: 400;
	padding: 5px 10px;
	border-radius: 10px;
	width: 230px;
}

.lang-select {
	margin-top: 20px;
}

.lang-select select {
	width: 100%;
}

table .column-content .lang-input {
	padding: 3px 10px !important;
	border-radius: 8px !important;
}

.contact-card {
	background-color: white;
	border-radius: 20px;
	padding: 37px 30px;
}

.contact-card .cc_top {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.contact-card .cc_title {
	color: #2c2c2c;
	font-weight: 700;
	font-size: 20px;
}

.contact-card .cc_edit-btn {
	background-color: transparent;
	color: #92929d;
}

.file-container .pi-title .pi-color {
	width: 16px;
	height: 32px;
	background-color: #b1e5fc;
	border-radius: 4px;
}

.textarea-container {
	position: relative;
	border: 2px solid #9a9fa540 !important;
	border-radius: 12px !important;
	overflow: hidden;
}

.textarea-container .options {}

.rdw-editor-toolbar {
	/* display: flex;
  align-items: center;
  gap: 24px; */
	background-color: white !important;
	padding: 12px !important;

	border: none !important;
	margin-bottom: 0px !important;
}

.textarea-container .options .option-left .editor-button {
	background-color: transparent;
}

.rdw-editor-main {
	background-color: #f4f4f4;
	border-top: none !important;
	border-radius: 0px !important;
	border-bottom-right-radius: 12px !important;
	border-bottom-left-radius: 12px !important;
	height: 112px !important;
}

.emoji-picker {
	position: absolute;
	top: 0px;
	left: 160px;
}

.label {
	color: #33383f;
	font-weight: 600;
	line-height: 24px;
	margin-bottom: 0.5rem;
}

.file-inner {
	height: 200px;
	border-radius: 12px;
	width: 100%;
	background-color: #f4f4f4;
}

.file-label {
	display: grid;
	place-items: center;
	height: 100%;
	border-radius: 12px;
}

.file-label div {
	padding: 12px 20px;
	border: 2px solid #efefef;
	border-radius: 12px;
	background-color: #fcfcfc;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-weight: 700;
	color: #1a1d1f;
	font-size: 15px;
}

.file-inner .file-input {
	display: none !important;
}

.form-check {
	display: flex !important;
	align-items: center !important;
	gap: 8px;
	font-weight: 400 !important;
	min-height: 2.5rem !important;
}

.radios {
	display: flex;
	align-items: center;
	gap: 100px;
}

.exercise-btn {
	color: #000;
	border-radius: 12px;
	font-weight: 700;
	font-size: 15px;
	padding: 12px 20px;
	margin-left: 20px;
}

.cc_bottom {
	margin-top: 20px;
}

.cc_bottom .links {
	display: flex;
	align-items: center;
	gap: 24px;
}

.online-title {
	border: none;
}

#dropdown-basic.select-multiple {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fcfcfc !important;
	border: 2px solid #efefef !important;
	font-size: 1rem;
	font-weight: 400;
	color: #212529;
	padding: 12px;
	border-radius: 12px !important;
}

#dropdown-basic.select-multiple.border-red {
	border: 1px solid red !important;
}

.edit-profile-password-form .border-red {
	border: 1px solid red !important;
}

.dropdown .form-check-label {
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#dropdown-basic span {
	width: 100%;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.user-avatar {
	padding: 5px 4px 3px;
	border-radius: 50%;
	background-color: #d5d5d5;
	display: grid;
	place-items: center;
}

.logo-img {
	width: 155px;
	height: 100%;
}

.css-dip3t8 {
	background-color: transparent !important;
}

.request-modal_title {
	font-size: 24px;
	font-weight: 600;
	color: #171725;
	margin-bottom: 16px;
}

.request-modal_subtitle {
	font-size: 20px;
	font-weight: 500;
	color: #696974;
	margin-top: 10px;
}

.login-logo {
	width: 300px;
}

.profile-avatar-image {
	border-radius: 50%;
	width: 43px;
	height: 43px;
}

/* 
.user_avatar {~
  border-radius: 50%;
  width: 32px;
  height: 32px;
} */

.profile-image-size {
	width: 110px;
	height: 110px;
}

.score-card {
	background-color: #2c2c2c;
	color: #fff;
	padding: 20px;
	border-radius: 20px;
}

.score-card .icon-inner {
	background-color: #03ccaa;
	display: grid;
	place-items: center;
	border-radius: 16px;
	padding: 10px;
	width: 38px;
	height: 38px;
}

.sc_score .score-content {
	margin-top: 12px;
}

.sc_score .score-content .score-text {
	font-size: 45px;
	font-family: "Plus Jakarta Sans", sans-serif;
	font-weight: 600;
}

.sc_score .score-content .score-subtitle {
	color: #8f8f8f;
	font-size: 13px;
	font-weight: 500;
	margin-top: 10px;
}

.switch {
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	text-align: center;
	font-size: 16px;
	padding: 4px;
	border-radius: 40px;
	text-align: center;
	width: 100%;
	background-color: #f4f4f4;
}

.switch-container {
	margin: 12px auto 20px;
	width: 320px;
	position: relative;
}

.switch .s_inner {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	font-weight: 600;
}

.switch-side {
	position: relative;
	z-index: 2;
	background-color: transparent;
	padding: 10px 20px;
	border-radius: 32px;
	color: #6f767e;
	transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.switch-side.active {
	color: #1a1d1f;
}

.switch-active {
	position: absolute;
	top: 4px;
	left: 4px;
	right: 4px;
	width: 50%;
	height: 44px;
	padding: 10px 20px;
	border-radius: 32px;
	background-color: #fff;
	box-shadow: 0px 4px 8px -4px #00000040, 0px 2px 0px 0px #ffffff40 inset,
		0px -1px 1px 0px #0000000a inset;
	transform: translateX(0);
	transition: transform 0.3s ease;
}

.switch-active.right {
	transform: translateX(95%);
}

.react-datepicker-wrapper,
.react-datepicker-wrapper input {
	width: 100%;
}

.react-datepicker__view-calendar-icon {
	display: flex !important;
	align-items: center !important;
	justify-content: space-between !important;
	flex-direction: row-reverse !important;
}

.react-datepicker__calendar-icon {
	width: auto !important;
	height: auto !important;
}

.top-students-image {
	width: 38px;
	height: 38px;
	border-radius: 100%;
}

.notes-inner {
	overflow: hidden;
	width: 400px;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.body-template {
	margin: 10px 0;
	font-size: 20px;
	text-align: center;
}

.request__message .icon-box {
	font-size: 100px;
	color: #b1b1b1;
}

.request__message .icon-box-p {
	color: #b1b1b1;
}

.message-container {
	position: relative;
}

.auto-complete_container {
	position: absolute;
	background-color: #fff;
	width: 100%;
	box-shadow: 0px 32px 48px -8px #0000001a !important;
	border-radius: 15px;
	border: 1px solid #f2eeeeaa;
	overflow: hidden;
}

.auto-complete_container .ac_list {
	/* padding: 10px 10px 10px 20px; */
	padding: 0px;
	margin: 0px;
}

.auto-complete_container .ac_list li {
	padding: 6px 6px 6px 15px;
	transition: all 0.1s linear;
	overflow: hidden;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.auto-complete_container .ac_list li:hover {
	background-color: #f4f4f4;
}

.file-display-list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 13px;
}

.file-display-inner {
	/* display: block; */
	width: 100%;
}

.file-display-inner .file-display {
	height: 185px;
	width: 100%;
	background-color: #fafafb;
	border: 1px solid #e2e2ea;
	border-radius: 10px;
	overflow: hidden;
}

.file-display-name,
.file-download-name {
	color: #92929d;
	font-size: 12px;
	display: block;
	margin-top: 10px;
}

.file-display-inner .file-display,
.file-download-inner .file-download {
	display: grid;
	place-items: center;
}

.file-display-img,
.file-download-img {
	width: 100%;
	height: 100%;
	/* object-fit: contain; */
}

.file-download-list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 13px;
	margin-top: 20px;
}

.file-download-wrapper {
	display: flex;
	align-items: start;
}

.file-download-wrapper a {
	width: calc(100% - 24px);
}

.file-download-wrapper .btn {
	padding: 0;
	width: 24px;
}

.file-download-inner .file-download {
	height: 100px;
	width: 100%;
	background-color: #fafafb;
	border: 1px solid #e2e2ea;
	border-radius: 10px;
	overflow: hidden;
}

.about-file-button {
	gap: 10px;
}

/* src/components/CustomDropdown.css */

.custom-dropdown {
	position: relative;
	width: 100%;
}

.dropdown-header {
	padding: 10px;
	border: 1px solid #ccc;
	cursor: pointer;
}

.dropdown-header.invalid {
	border-color: red;
}

.dropdown-list {
	position: absolute;
	width: 100%;
	max-height: 200px;
	/* Adjust max height for scrolling */
	overflow-y: auto;
	border: 1px solid #ccc;
	background: white;
	z-index: 1000;
}

.dropdown-list ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.dropdown-list li {
	padding: 10px;
	cursor: pointer;
}

.dropdown-list li:hover {
	background-color: #f0f0f0;
}

.dropdown-search {
	width: 100%;
	padding: 5px;
	box-sizing: border-box;
}

.group-schedules {
	margin-left: 10px;
}

.week-pagination {
	display: flex;
	align-items: center;
	gap: 22px;
}

.week-pagination button {
	background-color: transparent;
}

.week-pagination .wp_button {
	margin-bottom: -24px;
}

.week-pagination .wp_day {
	display: flex;
	align-items: center;
	gap: 14px;
}

.week-pagination .wp_day-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2px;
}

.week-pagination .wp_day-inner .wp_day-name {
	font-size: 14px;
	line-height: 24px;
	font-weight: 600;
}

.week-pagination .wp_day-button {
	padding: 4px 10px;
	line-height: 24px;
	font-weight: 600;
	border: 1px solid #cccccc;
	border-radius: 50%;
}

.week-pagination .wp_day-button.active_button {
	background-color: #1ae5be;
	border-color: transparent;
}

.week-pagination .wp_day-button span {
	display: inline-block;
	width: 12px;
}

.course-schedule {
	background-color: white;
	padding: 24px;
	border-radius: 8px;
}

.cs_container .cs_heading {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
}

.cs_title {
	font-size: 20px;
}

.cs_date {
	font-size: 16px;
	color: #707070;
	font-weight: 600;
}

.cs_table-container {
	overflow: hidden;
}

.cs_table {
	border: 1px solid #dedede;
	overflow-x: auto;
}

.cs_container .cs_table-inner {
	width: 100%;
	display: flex;
	border-top: 1px solid #dedede;
}

.cs_container .cs_table-inner:first-child {
	border-top: none;
}


.cs_container .cs_table-time {
	width: fit-content;
	padding: 20px 14px;
	height: calc((100% - 2px) / 13);
	border-right: 1px solid #dedede;
	position: sticky;
	left: 0;
	z-index: 3;
	background-color: #fff;
}

.cs_container .cs_table-card {
	width: 100%;
	position: relative;

}

.cs_container .cs_table-card .cs_table-card-wrap {
	width: 100%;
	max-height: 61px;
	border-top: 1px solid #dedede;
	display: flex;
	gap: 1px;
}

.cs_container .cs_table-card-wrap:first-child {
	border-top: none;
}

.cs_container .cs_table-time span {
	font-size: 10px;
	font-family: "Plus Jakarta Sans", sans-serif;
	display: inline-block;
	width: 41px;
	text-align: center;
}

/* .card-content-wrap {
	padding: 6px 13px 4px;
	background-color: #ffd3b4;
	width: 411px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
} */


.cs_column {
	position: relative;
	z-index: 1;
}

.card-content-wrap {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: auto;
	cursor: pointer;
	padding: 6px 13px 4px;
	background-color: #ffd3b4;
	width: 411px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 2;
}


.card-content-wrap .cc_content {
	display: flex;
	align-items: center;
	gap: 6px;
}

.card-content-wrap .cc_content {
	line-height: 14px;
}

.card-content-wrap .cc_content.cc_title span {
	font-weight: 600;
}

.card-content-wrap .cc_content span {
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

/* STUDENT */
.averages {
	font-family: "Plus Jakarta Sans", sans-serif;
}

.averages__item {
	position: relative;
	background: #FFFFFF;
	border-radius: 10px;
	padding: 20px;
}

.averages__icon {
	position: absolute;
	top: 18%;
	right: 7%;
}

.averages__icon svg {
	width: 35px;
	height: 35px;
}

.averages__title {
	color: #000000;
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
}

.averages__body {
	position: relative;
	color: #101110;
	padding-left: 25px;
}

.averages__body::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	background: #1AE5BE;
	width: 5px;
	height: 100%;
}

.averages__score {}

.score-total {
	font-size: 32px;
	font-weight: 600;
	line-height: 48px;
}

.score-100 {}

.averages__absent {
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
}


.grade-info {
	font-size: 13px;
	font-weight: 500;
	line-height: 17px;
	margin: 20px 0;
}

.bordered {
	border-radius: 6px;
	padding: 2px 7px;
	gap: 10px;
	margin-left: 10px;
}

.grade-info span:nth-child(0) {
	margin-bottom: 15px;
	display: inline-block;
}

.grade-info span:nth-child(1) {
	color: #101110;
}

.grade-info span:nth-child(2) {
	color: #101110;
	background: #1AE5BE;
}

.grade-info span:nth-child(3) {
	color: #FFFFFF;
	background: #246CF9;
}

.grade-info span:nth-child(4) {
	color: #1A1D1F;
	background: #FFB545;
}

.grade-info span:nth-child(5) {
	background: #FC5A5A;
}

.column-content-grade {
	width: 33px;
	height: 31px;
	border-radius: 10px;
	font-weight: 600;
	line-height: 21px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.online-item {
	background: #E5E6ED80;
	border-radius: 8px;
	font-weight: 500;
	padding: 15px;
}

.online-btn {
	background: #1AE5BE;
	border-radius: 10px;
	padding: 5px 15px;
}

.online-text {
	font-size: 16px;
	font-weight: 500;
	padding-left: 14px;
}

.user__groups-item {
	cursor: pointer;
}

.header-links {
	display: none;
}

.modal-group {
	display: none;
}

.add-grade .left .olumn-content {
	width: 500px;
}

.add-grade-td {
	width: 70%;
}

@media screen and (max-width: 768px) {
	.main-content {
		padding: 20px;
	}

	.sidebar {
		display: none;
	}

	.profile-menu-dropdown {
		display: none;
	}

	.dropdown-menu.show {
		/* display: none !important; */
	}

	.header-links {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.header-logout {
		/* background: #D5D5D5;
		width: 48px;
		height: 48px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center; */
		margin-left: 7px;
	}

	.user__right {
		display: none;
	}

	.user__bottom {
		display: none;
	}

	.modal-group {
		top: 40% !important;
	}

	.modal-group {
		display: block;
	}

	.modal-group-btn {
		color: #6f767e !important;
		font-family: Inter, sans-serif !important;
		font-size: 17px !important;
		font-weight: 600 !important;
		line-height: 24px !important;
		text-decoration: none !important;
		padding: 10px 7px !important;
	}

	.modal-group-btn:hover {
		background-color: #f8f9fa;
	}

	.modal-group-btn svg {
		margin-right: 10px;
	}

	.groups .groups-header {
		display: block;
	}

	.groups .groups-header .header-right {
		display: block;
		margin-top: 20px;
	}

	.header-right div {
		margin-bottom: 20px;
	}

	.header-right .group-subject {
		display: block;
	}

	.group-subject-name {
		/* width: 20%; */
		/* margin-right: 0; */
	}

	.selection {
		width: 100% !important;
	}

	.week-navigation {
		text-align: center;
	}

	.day-navigation {
		margin-bottom: 20px !important;
	}

	.groups-body {
		overflow-x: auto;
	}

	.add-grade .left .olumn-content {
		width: 200px;
	}

	.add-grade-td {
		width: 30%;
	}

	.add-grade-td {
		position: sticky;
		left: 0;
		z-index: 3;
		background-color: #fff;
	}

	.table .column-content {
		padding: 7px 10px 7px 0;
	}

	.button {
		padding: 6px 20px;
	}
}

.scrollable-dropdown {
	max-height: 200px; /* Yüksekliği ayarlayın */
	overflow-y: auto;
  }
  
  .dropdown-body {
	height: auto; /* Liste kısa olduğunda otomatik yükseklik */
  }
  